import MainLayout from "@/components/layouts/MainLayout";
import store from "@/store";

const changeMenuReporting = () => {
  store.dispatch("changeMenu", "reporting_monitoring");
};

export default [
  {
    path: "/reporting-monitoring/limit-principal",
    name: "reporting_monitoring.limit-principal.index",
    component: () => import("@/views/reporting_monitoring/limitPrincipal.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Reporting & Monitoring - Limit per Principal",
      breadcrumbs: [
        { name: "Reporting & Monitoring", url: "/" },
        { name: "Limit per Principal", url: "/" },
      ],
    },
    beforeEnter: changeMenuReporting,
  },
  {
    path: "/reporting-monitoring/limit-borrower",
    name: "reporting_monitoring.limit-borrower.index",
    component: () => import("@/views/reporting_monitoring/limitBorrower.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Reporting & Monitoring - Limit per Borrower",
      breadcrumbs: [
        { name: "Reporting & Monitoring", url: "/" },
        { name: "Limit per Borrower", url: "/" },
      ],
    },
    beforeEnter: changeMenuReporting,
  },
  {
    path: "/reporting-monitoring/laporan-fasilitas-principal",
    name: "reporting_monitoring.fasilitasi-principal.index",
    component: () =>
      import("@/views/reporting_monitoring/laporanFasilitasPrincipal.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Reporting & Monitoring - Fasilitas Principal",
      breadcrumbs: [
        { name: "Reporting & Monitoring", url: "/" },
        { name: "Fasilitas Principal", url: "/" },
      ],
    },
    beforeEnter: changeMenuReporting,
  },
  {
    path: "/reporting-monitoring/laporan-fasilitas-peminjaman",
    name: "reporting_monitoring.fasilitasi-peminjaman.index",
    component: () =>
      import("@/views/reporting_monitoring/fasilitasPeminjaman.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Reporting & Monitoring - Fasilitas per Pinjaman",
      breadcrumbs: [
        { name: "Reporting & Monitoring", url: "/" },
        { name: "Fasilitas per Pinjaman", url: "/" },
      ],
    },
    beforeEnter: changeMenuReporting,
  },
  {
    path: "/reporting-monitoring/pengajuan-dalam-proses",
    name: "reporting_monitoring.fasilitasi-peminjaman-borrower.index",
    component: () =>
      import("@/views/reporting_monitoring/pengajuanPinjaman.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Reporting & Monitoring - Pinjaman dalam Proses",
      breadcrumbs: [
        { name: "Reporting & Monitoring", url: "/" },
        { name: "Pinjaman Dalam Proses", url: "/" },
      ],
    },
    beforeEnter: changeMenuReporting,
  },
  {
    path: "/reporting-monitoring/regulator-report",
    name: "reporting_monitoring.regulator-report.index",
    component: () => import("@/views/reporting_monitoring/regulatorReport.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Regulator Report - OBOX",
      breadcrumbs: [
        { name: "Reporting & Monitoring", url: "/" },
        { name: "Regulator Report", url: "/" },
      ],
    },
    beforeEnter: changeMenuReporting,
  },
  {
    path: "/reporting-monitoring/laporan-pembayaran",
    name: "reporting_monitoring.laporan-pemmbayaran.index",
    component: () => import("@/views/reporting_monitoring/PaymentReport.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Laporan Pembayaran",
      breadcrumbs: [
        { name: "Reporting & Monitoring", url: "/" },
        { name: "Laporan Pembayaran", url: "/" },
      ],
    },
    beforeEnter: changeMenuReporting,
  },
];
