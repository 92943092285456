<template>
  <form @submit.prevent="submit">
    <p class="fw-bold text-muted mb-2">Filter Data</p>

    <div class="row">
      <div class="col-md-8">
        <div class="row g-3">
          <div class="col-5">
            <base-input
              placeholder="Nama Borrower"
              v-model="filter.borrower_name"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3 offset-md-1 d-flex gap-3 justify-content-end">
        <button
          type="submit"
          class="btn btn-outline-warning fw-bold outline-none shadow-none px-4"
        >
          Apply
        </button>
        <button
          @click="reset"
          type="reset"
          class="btn btn-outline-primary border-0 fw-bold outline-none shadow-none px-4"
        >
          Reset
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    submit: {
      type: Function,
      default: () => {},
    },
    reset: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      filter: {
        borrower_name: "",
      },
    };
  },
  watch: {
    "filter.borrower_name"(newValue) {
      this.$emit("setborrowerName", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
$dp__border_radius: 4px !default;
</style>
