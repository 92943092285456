<template>
  <div v-if="show">
    <div class="popup-backdrop" />

    <div class="popup-content">
      <div class="d-flex justify-content-between my-3">
        <h3 class="text-xl fw-bold">Pusat Bantuan</h3>
      </div>
      <div class="text-lg text-gray-500">
        <p>
          Nasabah dapat menyampaikan pengaduannya ke PT. Sistem Integrasi
          Aplikasi Pembiayaan melalui
        </p>
      </div>
      <div class="d-flex justify-content-between mt-3 mb-4">
        <div class="text-lg">
          <div class="text-gray-500 mb-1">Call center</div>
          <div class="fw-bold">1500899 ext 7</div>
        </div>
        <div class="text-lg">
          <div class="text-gray-500 mb-1">Email</div>
          <div class="fw-bold">support@motioncrypto.id</div>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-3">
        <button
          @click="() => !loading && handleClose()"
          type="button"
          class="btn btn-warning w-50"
        >
          Tutup
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      type: Function,
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 906;
  transition: opacity 0.15s linear;
}

.popup-close {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 907;

  cursor: pointer;
}

.popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 907;
  transform: translate(-50%, -50%);

  background-color: white;
  width: 38vw;
  border-radius: 16px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
  padding: 40px;
}
</style>
