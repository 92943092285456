<template>
  <alert-success
    message="Pengajuan Berhasil Ditolak"
    v-show="alerts.invoiceRejected"
  />

  <alert-success
    message="Berhasil Konfirmasi Transfer"
    v-show="alerts.invoiceApproved"
  />

  <alert-failed
    style="z-index: 9999"
    :message="message"
    v-show="alerts.error"
  />
  <alert-failed v-show="alerts.failedUpload" :message="alerts.message" />

  <base-modal
    modal-name="disburse-preview"
    :show-close="true"
    size="xl"
    :on-closed="resetDetail"
  >
    <template #title>
      <h3 class="fw-bold" style="font-size: 24px">Detail</h3>
    </template>
    <template #content>
      <div class="card rounded-lg">
        <div class="card-body">
          <disbursment-table :disbursment="disbursmentData" />
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-end gap-3">
        <modal-button
          color="danger"
          data-bs-toggle="modal"
          data-bs-target="#disburse-preview"
          label="Batal"
          class="w-25"
        />
        <modal-button
          v-if="this.isHaveAccess('disbursement/update')"
          color="success"
          modal="disburse-detail"
          label="Lanjut"
          class="w-25"
        />
      </div>
    </template>
  </base-modal>

  <base-modal
    modal-name="disburse-detail"
    :show-close="true"
    size="xl"
    :on-closed="resetDetail"
  >
    <template #title>
      <h3 class="fw-bold" style="font-size: 24px">Disbursement Detail</h3>
    </template>

    <template #content>
      <div class="card rounded-lg">
        <div class="card-body">
          <div class="row mb-5" v-if="disbursment">
            <div class="col-lg-12 mb-4">
              <div class="card">
                <div class="card-body text-center p-0">
                  <h5
                    class="card-title text-center"
                    @click="loansOpeningToggleCollapse"
                    data-bs-toggle="collapse"
                    href="#loansOpening"
                    role="button"
                    aria-controls="loansOpening"
                    :aria-expanded="isExpandedLoansOpening ? 'true' : 'false'"
                  >
                    <div class="d-flex justify-content-between">
                      <span>Loans Opening</span>
                      <span
                        ><Icon
                          :name="
                            isExpandedLoansOpening ? 'ArrowUp' : 'ArrowBottom'
                          "
                          size="sm"
                          color="#1A1A1A"
                      /></span>
                    </div>
                  </h5>
                </div>
                <div class="row collapse" id="loansOpening">
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Customer ID" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.customer_id"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Currency" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.currency"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Loan Amount 1" />
                      <base-input
                        placeholder=""
                        v-model="formattedLoanAmount"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Marketing Code" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.marketing_code"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Loan Start Date" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.loan_start_date"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Loan Maturity Date" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.loan_maturity_date"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Type Loan" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.type_loan"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Loan Product" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.loan_product"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Interest Key" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.interest_type"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Interest Basis" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.interest_basis"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Interest Rate (Fixed). 1" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.loans_opening.interest_rate_fixed_1
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Interest Key" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.interest_key"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Interest Spread" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.interest_spread"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="New Interest Rate. 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.new_interest_rate_1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Interest Rate Eff Date" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.loans_opening.interest_rate_eff_date
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Penalty Rate" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.penalty_rate"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Floating Marker" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.floating_marker"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Pay Method (Begin / End)" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.pay_method_begin_end"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Limit Reference" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.limit_reference"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Tujuan Penggunaan" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.liquidation_mode"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Back To Back" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.back_to_back"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Tujuan Penggunaan" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.tujuan_penggunaan"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Annuity Start Date" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.loans_opening.annuity_start_date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mb-4">
              <div class="card">
                <div class="card-body text-center p-0">
                  <h5
                    class="card-title text-center"
                    @click="intAndSchedDetailsToggleCollapse"
                    data-bs-toggle="collapse"
                    href="#intAndSchedDetails"
                    role="button"
                    aria-controls="intAndSchedDetails"
                    :aria-expanded="
                      isExpandedIntAndSchedDetails ? 'true' : 'false'
                    "
                  >
                    <div class="d-flex justify-content-between">
                      <span>Int And Sched Details</span>
                      <span
                        ><Icon
                          :name="
                            isExpandedIntAndSchedDetails
                              ? 'ArrowUp'
                              : 'ArrowBottom'
                          "
                          size="sm"
                          color="#1A1A1A"
                      /></span>
                    </div>
                  </h5>
                </div>
                <div class="row collapse" id="intAndSchedDetails">
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="In Collection Method" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.in_collection_method
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Capitalise Intt" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.capitalise_intt
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Repayment Of Cap Int" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.repayment_of_cap_int
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Intt Accural Method" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.intt_accural_method
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Interest Due Date" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.interest_due_date
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Total Interest Amount" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details
                            .total_interest_amount
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Forward Backward Key" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.forward_backward_key
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Base Data Key" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.base_date_key
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Auto Schedules" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.auto_schedules
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Define Schedules" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.define_schedules
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Secured" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.int_and_sched_details.secured"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Rounding Rule" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.int_and_sched_details.rounding_rule
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mb-4">
              <div class="card">
                <div class="card-body text-center p-0">
                  <h5
                    class="card-title text-center"
                    @click="accountDetailsToggleCollapse"
                    data-bs-toggle="collapse"
                    href="#accountDetails"
                    role="button"
                    aria-controls="accountDetails"
                    :aria-expanded="isExpandedAccountDetails ? 'true' : 'false'"
                  >
                    <div class="d-flex justify-content-between">
                      <span>Account Details</span>
                      <span
                        ><Icon
                          :name="
                            isExpandedAccountDetails ? 'ArrowUp' : 'ArrowBottom'
                          "
                          size="sm"
                          color="#1A1A1A"
                      /></span>
                    </div>
                  </h5>
                </div>
                <div class="row collapse" id="accountDetails">
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Drawdown Account" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.account_details.draw_down_account"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Comm Liq Account" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.account_details.comm_liq_account"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Principal Liq Account" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.account_details.principal_liq_account
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Prin Ben Bank 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.account_details.prin_ben_bank_1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Prin Ben Bank 2" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.account_details.prin_ben_bank_2"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Prin BenBk Name 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.account_details.prin_ben_bk_name_1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Prin Ben Acct" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.account_details.prin_ben_acct"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Int Liq Acct" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.account_details.int_liq_acct"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Bank To Bank Info 1" />
                      <base-input
                        placeholder=""
                        v-model="
                          disbursment.account_details.bank_to_bank_info_1
                        "
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Free Pay Account" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.account_details.fee_pay_account"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mb-4">
              <div class="card">
                <div class="card-body text-center p-0">
                  <h5
                    class="card-title text-center"
                    @click="chargeToggleCollapse"
                    data-bs-toggle="collapse"
                    href="#charge"
                    role="button"
                    aria-controls="charge"
                    :aria-expanded="isExpandedCharge ? 'true' : 'false'"
                  >
                    <div class="d-flex justify-content-between">
                      <span>Charge</span>
                      <span
                        ><Icon
                          :name="isExpandedCharge ? 'ArrowUp' : 'ArrowBottom'"
                          size="sm"
                          color="#1A1A1A"
                      /></span>
                    </div>
                  </h5>
                </div>
                <div class="row collapse" id="charge">
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Charge Liq Acct" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.charge.charge_liq_acct"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Charge Code 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.charge.charge_code_1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Charge Amount 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.charge.charge_code_1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Charge Date 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.charge.charge_date_1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Charge Booked on 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.charge.charge_booked_on_1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Capitalise Charge 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.charge.capitalise_charge_1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mb-4">
              <div class="card">
                <div class="card-body text-center p-0">
                  <h5
                    class="card-title text-center"
                    @click="adviceDetailsToggleCollapse"
                    data-bs-toggle="collapse"
                    href="#adviceDetails"
                    role="button"
                    aria-controls="adviceDetails"
                    :aria-expanded="isExpandedAdviceDetails ? 'true' : 'false'"
                  >
                    <div class="d-flex justify-content-between">
                      <span>Advice Details</span>
                      <span
                        ><Icon
                          :name="
                            isExpandedAdviceDetails ? 'ArrowUp' : 'ArrowBottom'
                          "
                          size="sm"
                          color="#1A1A1A"
                      /></span>
                    </div>
                  </h5>
                </div>
                <div class="row collapse" id="adviceDetails">
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Send Payment" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.advice_details.sent_payment"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Send Confirmation" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.advice_details.send_confirmation"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Customer Ref" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.advice_details.customer_ref"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Customer Remarks 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.advice_details.customer_remarks_1"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Customer Remarks 2" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.advice_details.customer_remarks_2"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="p-4">
                      <label-regular label="Advice Activity 1" />
                      <base-input
                        placeholder=""
                        v-model="disbursment.advice_details.advice_activity_1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-end gap-3">
        <modal-button
          data-bs-toggle="modal"
          data-bs-target="#disburse-preview"
          label="Kembali"
          class="btn shadow-none w-25 outline-none btn-outline-warning px-4 py-2 color-primary"
        />
        <modal-button
          v-if="this.isHaveAccess('disbursement/update')"
          color="success"
          modal="disburse-approve"
          label="Lanjut"
          class="btn shadow-none w-25 outline-none btn-warning px-4 py-2"
        />
      </div>
    </template>
  </base-modal>

  <base-modal
    modal-name="disburse-approve"
    :show-close="true"
    size="xl"
    :on-closed="resetDetail"
  >
    <template #title>
      <h4 class="fw-bold">Bukti Transfer</h4>
    </template>

    <template #content>
      <form @submit.prevent="disbursed">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <div class="fw-bold">Upload Bukti Transfer</div>
              <div>Upload Bukti Transfer atas pencairan loan borrower</div>
            </div>
            <DropZonePdf
              v-if="detail.prove_upload_file === '' && !loadingUpload"
              id="foto-bukti"
              :objectName="detail.prove_upload_file"
              :handleChange="
                (e) => handleChangePdf(e, 'foto-bukti', false, 'foto-bukti')
              "
              :percentage="progressBar"
              :percentageId="progressBarId"
            />
            <template v-else-if="loadingUpload">
              <div class="text-center py-4">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div class="fw-bold">Uploading..</div>
              </div>
            </template>
            <document-card
              v-else-if="detail.prove_upload_file && !loadingUpload"
              :label="file.name"
              extension="Jpg"
            >
              <a href="#" class="ms-auto text-muted" @click="deleteFile">
                <font-awesome icon="times"></font-awesome>
              </a>
            </document-card>
          </div>
        </div>

        <div class="row mt-3" v-show="detail.prove_upload_file != ''">
          <div class="col-md-3">
            <label-regular label="Nomor Loan" />
            <base-input
              type="alphanumerical"
              v-model="detail.loan_no"
              placeholder="No. Loan"
            />
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-warning fw-bold float-end mt-3 w-25"
          :disabled="disabledSubmit || onLoading"
        >
          Konfirmasi Transfer
        </button>
      </form>

      <button
        type="button"
        class="btn btn-secondary d-none"
        ref="close-modal"
        data-bs-dismiss="modal"
      >
        Close
      </button>
    </template>
  </base-modal>

  <base-modal :modal-name="`rejection-${disbursmentId}`">
    <template #content>
      <div class="text-center">
        <h3 class="fw-bold">Tolak Pengajuan</h3>
        <p>Anda menolak permintaan pencairan, mohon isi alasan penolakan.</p>
      </div>

      <form @submit.prevent="reject">
        <textarea
          v-model="rejection.reason"
          class="form-control outline-none shadow-none"
          cols="30"
          rows="5"
        ></textarea>

        <div class="d-flex gap-3 mt-4">
          <dismiss-button
            color="outline-warning"
            label="Kembali"
            class="w-50"
            ref="close-rejection"
          />
          <button
            class="btn btn-primary w-50 fw-bold"
            :disabled="disabledReject || onLoading"
          >
            Lanjutkan
          </button>
        </div>
      </form>
    </template>
  </base-modal>
</template>

<script>
import UploadService from "@/services/upload";
import AcceptanceService from "@/services/acceptance";
import DisbursementService from "@/services/disbursement";
import modal from "@/utils/modal";
import mixin from "@/mixin";
import useVuelidate from "@vuelidate/core";
import { mapActions } from "vuex";

export default {
  mixins: [modal, mixin],
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    disbursmentId: {
      type: [String, Number],
      default: "",
    },
    disbursmentData: {
      type: Object,
    },
    disbursment: {
      type: Object,
    },
  },
  data() {
    return {
      isExpandedLoansOpening: false,
      isExpandedIntAndSchedDetails: false,
      isExpandedAccountDetails: false,
      isExpandedCharge: false,
      isExpandedAdviceDetails: false,
      file: {
        url: "",
        is_public: true,
        expired_at: "",
        name: "",
        used_to: "disbursment",
        object_name: "",
      },
      detail: {
        loan_no: "",
        prove_upload_file: "",
      },
      rejection: {
        reason: "",
      },
      document: "",
      pdf: "",
      alerts: {
        invoiceRejected: false,
        invoiceApproved: false,
        error: false,
        failedUpload: false,
      },
      filter: {
        page: 1,
        per_page: 10,
        statuses: "need-disbursed",
      },
      message: "",
      onLoading: false,
      loadingUpload: false,
      progressBar: 0,
      progressBarId: "",
    };
  },
  computed: {
    oneWeekAgo() {
      const now = new Date();

      return new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    },
    disabledSubmit() {
      return this.detail.prove_upload_file == "" || this.detail.loan_no == "";
    },
    disabledReject() {
      return this.rejection.reason == "";
    },
    formattedLoanAmount() {
      let valueWithoutSeparator =
        this.disbursment.loans_opening.loan_amount_1.replace(/,/g, "");
      let reversedValue = valueWithoutSeparator.split("").reverse().join("");
      let formattedValue = "";
      for (let i = 0; i < reversedValue.length; i++) {
        if (i % 3 === 0 && i !== 0) {
          formattedValue += ".";
        }
        formattedValue += reversedValue[i];
      }
      return formattedValue.split("").reverse().join("");
    },
  },
  methods: {
    ...mapActions("acceptanceModule", ["retrieveAcceptances"]),
    deleteFile() {
      this.detail.prove_upload_file = "";
    },
    loansOpeningToggleCollapse() {
      this.isExpandedLoansOpening = !this.isExpandedLoansOpening;
    },
    intAndSchedDetailsToggleCollapse() {
      this.isExpandedIntAndSchedDetails = !this.isExpandedIntAndSchedDetails;
    },
    accountDetailsToggleCollapse() {
      this.isExpandedAccountDetails = !this.isExpandedAccountDetails;
    },
    chargeToggleCollapse() {
      this.isExpandedCharge = !this.isExpandedCharge;
    },
    adviceDetailsToggleCollapse() {
      this.isExpandedAdviceDetails = !this.isExpandedAdviceDetails;
    },
    getExtensionName(filename) {
      return filename.split(".").pop();
    },
    async handleChangePdf(event, elementId, isPublic, formName) {
      try {
        this.loadingUpload = true;
        const fileExtension = this.getExtensionName(
          event.target.files[0].name
        ).toLowerCase();
        if (
          fileExtension !== "jpg" &&
          fileExtension !== "jpeg" &&
          fileExtension !== "png" &&
          fileExtension !== "pdf"
        ) {
          event.target.value = "";
          this.showAlert("failedUpload", "file extension not allowed");
        } else if (event.target.files[0].size > 10000000) {
          event.target.value = "";
          this.showAlert("failedUpload", "file size too big, maximum 2MB");
        } else {
          const data = new FormData();
          data.append("file", event.target.files[0]);
          data.append("is_public", isPublic);
          const config = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let percent = Math.floor((loaded * 100) / total);
              this.progressBarId = elementId;
              this.progressBar = percent;
            },
            headers: { "Content-Type": "multipart/form-data" },
          };

          try {
            this.file.name = event.target.files[0].name;
            const resUpload = await UploadService.upload(data, config);
            this.progressBar = 0;
            this.progressBarId = "";

            if (resUpload.data.success) {
              this.loadingUpload = false;
              this.detail.prove_upload_file = resUpload.data.data.object_name;
            }

            event.target.value = "";
          } catch (err) {
            this.message = err.response.data.message;
            this.loadingUpload = false;
            this.showAlert("error");
            this.progressBar = 0;
            this.progressBarId = "";
            event.target.value = "";
          }
        }
      } catch (error) {
        if (error.response) {
          this.errors = { show: true, message: error.response.data.message };
        } else {
          this.errors = { show: true, message: error };
        }
        this.progressBar = 0;
        this.progressBarId = "";
        this.loadingUpload = false;
        this.message = error.response.data.message;
        this.showAlert("error");
        event.target.value = "";
      }
    },
    async getDocument(disburseId) {
      const response = await AcceptanceService.getFile(disburseId);
      const documentUrl = response.data.data;

      this.document = documentUrl.url;
    },
    async reject() {
      try {
        this.onLoading = true;

        const response = await AcceptanceService.reject(
          this.disbursmentId,
          this.rejection
        );

        if (response.data.success) {
          this.$refs["close-rejection"].$el.click();
          this.showAlert("invoiceRejected");
        }
        this.message = response.response.data.message;
        this.showAlert("error");

        this.onLoading = false;
      } catch (error) {
        this.onLoading = false;
        this.message = error.response.data.message;
        this.showAlert("error");
      }
    },
    async disbursed() {
      try {
        this.onLoading = true;

        const response = await DisbursementService.disburePaymentById(
          this.disbursmentId,
          this.detail
        );

        if (response.status == 200 || response.status == 201) {
          this.$refs["close-modal"].click();
          this.showAlert("invoiceApproved");
          this.retrieveAcceptances(this.filter);

          this.resetDetail();

          setTimeout(() => {
            this.$router.push(
              {
                name: "disbursment",
              },
              3000
            );
          });

          this.$emit("reload");
        } else {
          this.message = response.response.data.message;
          this.showAlert("error");
          this.resetDetail();
          this.onLoading = false;
        }

        this.onLoading = false;
      } catch (error) {
        this.onLoading = false;

        if (error.response.data.error_code === "400063") {
          this.message = "Pencairan gagal, data tidak sesuai.";
          this.showAlert("error");

          return;
        }

        this.message = error.response.data.message;
        this.showAlert("error");
      }
    },
    showAlert(alert, message) {
      this.alerts[alert] = true;
      this.alerts.message = message;
      setTimeout(() => {
        this.alerts[alert] = false;
      }, 3000);
    },
    resetDetail() {
      this.detail = {
        loan_no: "",
        prove_upload_file: "",
      };
    },
    formatDate(date) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options);
    },
  },
  watch: {
    "disbursment.loans_opening.loan_start_date": function (newDate, oldDate) {
      if (newDate && /^\d{8}$/.test(newDate)) {
        const date = new Date(
          newDate.substring(0, 4),
          parseInt(newDate.substring(4, 6)) - 1,
          newDate.substring(6, 8)
        );

        if (!isNaN(date.getTime())) {
          const formattedDate = this.formatDate(date);

          this.disbursment.loans_opening.loan_start_date = formattedDate;
        }
      }
    },
    "disbursment.loans_opening.annuity_start_date": function (
      newDate,
      oldDate
    ) {
      if (newDate && /^\d{8}$/.test(newDate)) {
        const date = new Date(
          newDate.substring(0, 4),
          parseInt(newDate.substring(4, 6)) - 1,
          newDate.substring(6, 8)
        );

        if (!isNaN(date.getTime())) {
          const formattedDate = this.formatDate(date);

          this.disbursment.loans_opening.annuity_start_date = formattedDate;
        }
      }
    },
    "disbursment.loans_opening.loan_maturity_date": function (
      newDate,
      oldDate
    ) {
      if (newDate && /^\d{8}$/.test(newDate)) {
        const date = new Date(
          newDate.substring(0, 4),
          parseInt(newDate.substring(4, 6)) - 1,
          newDate.substring(6, 8)
        );

        if (!isNaN(date.getTime())) {
          const formattedDate = this.formatDate(date);

          this.disbursment.loans_opening.loan_maturity_date = formattedDate;
        }
      }
    },
    "disbursment.int_and_sched_details.interest_due_date": function (
      newDate,
      oldDate
    ) {
      if (newDate && newDate.length >= 8) {
        const datePart = newDate.substring(0, 8);

        const year = datePart.substring(0, 4);
        const month = parseInt(datePart.substring(4, 6)) - 1;
        const day = datePart.substring(6, 8);
        const date = new Date(year, month, day);

        if (!isNaN(date.getTime())) {
          const formattedDate = this.formatDate(date);

          const additionalCharacters = newDate.substring(8);

          const formattedValue = `${formattedDate} ${additionalCharacters}`;

          this.disbursment.int_and_sched_details.interest_due_date =
            formattedValue;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 16px;
}

.card-title {
  border-radius: 16px 16px 0px 0px;
  background: #f3f3f3;
  padding: 14px 24px;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
}
</style>
