import { api } from "@/libs/api";

class CustomerService {
  async getCustomers(payload) {
    return await api.get("/customers", {
      params: payload,
    });
  }

  async getCustomersApproval(payload) {
    return await api.get("/customers/approval", {
      params: payload,
    });
  }

  async getCustomersExist(email) {
    return await api.get(`/customers/exist?email=${email}`);
  }

  async activateCustomer(payload) {
    return await api.put("/customers/activate", payload);
  }

  async deactiveCustomer(payload) {
    return await api.put("/customers/deactivate", payload);
  }

  async configuration(payload) {
    return await api.put("/customers/konfigurasi", payload);
  }

  async getConfigurationHistories(payload) {
    return await api.get("/customers/konfigurasi/riwayat", { params: payload });
  }

  async approvalApprove(payload) {
    return await api.put("/customers/approval/approve", payload);
  }

  async approvalReject(payload) {
    return await api.put("/customers/approval/reject", payload);
  }

  async getApprovalByCustId(id) {
    return await api.get(`/customers/approval/${id}`);
  }

  async getCustomerDetails(payload) {
    return await api.get("/customers/registration", {
      params: payload,
    });
  }

  async resendEmailActivation(payload) {
    return await api.post("/customers/resend-email-activation", payload);
  }
}

export default new CustomerService();
