<template>
  <div class="input-group border rounded-pill">
    <span class="input-group-text bg-transparent border-0" ref="blur">
      <span class="fw-bold">Rp</span>
    </span>
    <input
      type="text"
      class="form-control border-0 outline-none shadow-none"
      :placeholder="placeholder"
      :value="modelValue"
      ref="inputRef"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
  <div class="input-errors" v-for="(error, index) of errors" :key="index">
    <div class="text-danger mt-2 text-sm">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);

    return { inputRef };
  },
  props: {
    placeholder: {
      required: true,
      type: String,
    },
    modelValue: {
      type: String,
    },
    onBlur: {
      type: Function,
    },
    errors: {
      type: Array,
    },
    options: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  border: 1px solid #ced4da;
  padding: 10px 14px;
  font-size: 15px;
  color: #535353;
  background-color: #fff;
  border-radius: 100px !important;
  height: 43px;

  &::placeholder {
    color: #b4b4b4;
    text-align: left;
  }

  &:focus {
    outline: none;
    border: 1px solid #081462;
  }

  &:disabled {
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .btn {
    padding: 0.75rem !important;
    font-size: 15px !important;
  }
}
</style>
