<template>
  <div class="input-group border border-rounded" v-if="position == 'right'">
    <input
      :type="type"
      class="form-control border-0 outline-none shadow-none"
      :step="step"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
      @keypress="type === 'number' && isNumber($event)"
      @keyup.delete="type === 'number' && isNumber($event)"
      @paste="type === 'number' && isNumber($event)"
      @blur="
        () => {
          onBlur && onBlur();
        }
      "
    />
    <span class="input-group-text bg-transparent border-0" id="basic-addon2">
      <slot></slot>
    </span>
  </div>
  <div class="input-group border border-rounded" v-else>
    <span class="input-group-text bg-transparent border-0" id="basic-addon2">
      <slot></slot>
    </span>
    <input
      :type="type"
      class="form-control border-0 outline-none shadow-none"
      :placeholder="placeholder"
      :value="modelValue"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
      @keypress="type === 'number' && isNumber($event)"
      @keyup.delete="type === 'number' && isNumber($event)"
      @paste="type === 'number' && isNumber($event)"
      @blur="
        () => {
          onBlur && onBlur();
        }
      "
    />
  </div>
  <div class="input-errors" v-for="(error, index) of errors" :key="index">
    <div class="text-danger mt-2 text-sm">
      {{ error.$message }}
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";
export default {
  mixins: [mixin],
  props: {
    type: {
      default: "text",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: true,
      type: String,
    },
    step: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    onBlur: {
      type: Function,
    },
    errors: {
      type: Array,
    },
    position: {
      type: String,
      default: "right",
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  border: 1px solid #ced4da;
  padding: 10px 14px;
  font-size: 15px;
  color: #535353;
  background-color: #fff;
  border-radius: 100px !important;
  height: 43px;

  &::placeholder {
    color: #b4b4b4;
    text-align: left;
  }

  &:focus {
    outline: none;
    border: 1px solid #081462;
  }

  &:disabled {
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .btn {
    padding: 0.75rem !important;
    font-size: 15px !important;
  }
}
</style>
