<template>
  <div>
    <div class="form-label text-muted mb-1">
      {{ label }} <span class="text-danger" v-if="required">*</span>
    </div>
    <div v-if="type === 'text'" class="position-relative">
      <input
        :id="id"
        type="text"
        :value="modelValue"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :minlength="minlength"
        @input="
          (e) =>
            isInt
              ? updateNumber(e, false)
              : isBigInt
              ? updateNumber(e, isBigInt)
              : updateInput(e)
        "
        class="form-input w-100"
        @focus="onFocus"
        @blur="
          () => {
            onBlur();
            handleBlur && handleBlur();
          }
        "
        :disabled="disabled"
        :required="required"
      />

      <div
        class="d-flex align-items-center"
        v-if="maxlength > 0 && isHaveLength"
      >
        <div
          class="position-absolute text-sm color-gray-2 ps-2 bg-white"
          style="right: 10px; top: 13px"
        >
          {{ modelValue?.length }}/{{ maxlength }}
        </div>
      </div>

      <div class="d-flex align-items-center" v-if="isCheck">
        <div
          class="position-absolute text-sm color-primary fw-700 cursor-pointer ps-2"
          style="right: 13px; top: 13px"
        >
          <div
            class="spinner-border spinner-border-sm"
            role="status"
            v-if="isLoading"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <div v-if="!isLoading" @click="onSubmit">Check</div>
        </div>
      </div>
    </div>

    <div class="input-errors" v-for="(error, index) of errors" :key="index">
      <div class="text-danger mt-2 text-sm">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";
export default {
  mixins: [mixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [Number, String, null],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: [Number, String],
      default: null,
    },
    minlength: {
      type: [Number, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    handleBlur: {
      type: Function,
    },
    isCheck: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
    },
    errors: {
      type: Array,
    },
    decimal: {
      type: String,
      default: ",",
    },
    separator: {
      type: String,
      default: ".",
    },
    prefix: {
      type: String,
      default: "",
    },
    precision: {
      type: Number,
      default: 20,
    },
    masked: {
      type: Boolean,
      default: false,
    },
    isHaveLength: {
      type: Boolean,
      default: false,
    },
    isInt: {
      type: Boolean,
      default: false,
    },
    isBigInt: {
      type: Boolean,
      default: false,
    },
  },

  name: "InputText",
  data() {
    return {
      isFocus: false,
      isRendered: false,
    };
  },
  methods: {
    onFocus() {
      this.isFocus = true;
    },
    onBlur() {
      this.isFocus = false;
    },
    updateInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    updateNumber(event, isBigInt) {
      const maxNumber = isBigInt ? BigInt("9223372036854775807") : 2147483647;

      if (stringToNumber(event.target.value) > maxNumber) {
        event.preventDefault();
      } else {
        let number = inputNumberFormat(event.target.value);
        this.$emit("update:modelValue", this.removeLeadingZeros(number));
      }
    },
  },
  mounted() {
    this.isRendered = true;
  },
};
</script>

<style lang="scss" scoped>
.form-label {
  font-weight: 700;
  font-size: 15px;
}
.form-input {
  border: 1px solid #ced4da;
  padding: 10px 14px;
  font-size: 15px;
  color: #535353;
  background-color: #fff;
  border-radius: 100px !important;
  height: 43px;

  &::placeholder {
    color: #b4b4b4;
    text-align: left;
  }

  &:focus {
    outline: none;
    border: 1px solid #081462;
  }

  &:disabled {
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
</style>
