import MainLayout from "@/components/layouts/MainLayout";
import store from "@/store";

const changeMenuAudit = () => {
  store.dispatch("changeMenu", "auditLog");
};

const routes = [
  {
    path: "/audit-log/backoffice",
    name: "audit-log.backoffice",
    component: () => import("@/views/audit_log/BackofficeView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Backoffice Log",
      breadcrumbs: [
        { name: "Audit Log", url: "/" },
        { name: "Backoffice Log", url: "/" },
      ],
    },
    beforeEnter: changeMenuAudit,
  },
  {
    path: "/audit-log/borrower",
    name: "audit-log.borrower",
    component: () => import("@/views/audit_log/BorrowerView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Borrower Log",
      breadcrumbs: [
        { name: "Audit Log", url: "/" },
        { name: "Borrower Log", url: "/" },
      ],
    },
    beforeEnter: changeMenuAudit,
  },
  {
    path: "/audit-log/principle",
    name: "audit-log.principle",
    component: () => import("@/views/audit_log/PrincipleView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Principal Log",
      breadcrumbs: [
        { name: "Audit Log", url: "/" },
        { name: "Principal Log", url: "/" },
      ],
    },
    beforeEnter: changeMenuAudit,
  },
];

export default routes;
