import { api } from "@/libs/api";

class PrincipleService {
  async retrieve(payload) {
    return await api.get("/principle", {
      params: payload,
    });
  }

  async principalDetail(principleId) {
    return await api.get(`/principle/${principleId}`);
  }

  async detail(principleId) {
    return await api.get(`/principle/detail/${principleId}`);
  }

  async detailPrinciple(principleId) {
    return await api.get(`/principle/registration-detail/${principleId}`);
  }

  async show(principleId, params) {
    const response = await api.get(`/principle`, { params });
    return response.data.data.items.find((principle) => {
      return principle.principle_id === principleId;
    });
  }

  async deactivated(registrationId, payload) {
    return await api.post(
      `/registration/principle/${registrationId}/deactivate`,
      payload
    );
  }

  async activated(registrationId, payload) {
    return await api.post(
      `/registration/principle/${registrationId}/active`,
      payload
    );
  }

  async resubmit(payload) {
    return await api.post(`/registration/principle/override`, payload);
  }

  async getBorrowerList(principleId) {
    return await api.get(`/principle/${principleId}/borrower`);
  }

  async verifyComitee(payload) {
    return await api.post("/registration/principle/verify/committee", payload);
  }

  async verifyData(payload) {
    return await api.post("/registration/principle/verify/data", payload);
  }

  async getPrincipalApprovals(payload) {
    return await api.get("/principals/approval", {
      params: payload,
    });
  }

  async getPrincipalChangeHistories(payload) {
    return await api.get("/principals/approval/riwayat", {
      params: payload,
    });
  }

  async getPrincipalChangebyId(id) {
    return await api.get(`/principals/approval/${id}`);
  }

  async updatePrincipalRegistration(payload) {
    return await api.put("/principals/approval/registrasi-fasilitas", payload);
  }

  async approveUpdatePrincipal(payload) {
    return await api.put("/principals/approval/approve", payload);
  }

  async rejectUpdatePrincipal(payload) {
    return await api.put("/principals/approval/reject", payload);
  }

  async postPrincipalRegistration(payload) {
    return await api.post(`/registration/principle`, payload);
  }
}

export default new PrincipleService();
