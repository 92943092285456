<template>
  <table class="table table-striped table-bordered">
    <caption>
      Rincian Borrower
    </caption>
    <tbody>
      <tr>
        <td>Nama Borrower</td>
        <th scope>{{ disbursment?.invoice?.borrower_name }}</th>
      </tr>
      <tr>
        <td>Principal</td>
        <th scope>{{ disbursment?.registration?.principle_name }}</th>
      </tr>
      <tr>
        <td>Tipe Borrower</td>
        <th scope>
          <span class="text-capitalize">
            {{ disbursment?.registration.financing_program }}
          </span>
        </th>
      </tr>
      <tr>
        <td>Jenis Fasilitas</td>
        <th scope>{{ disbursment?.product }}</th>
      </tr>
      <tr>
        <td>Grace Period</td>
        <th scope>{{ disbursment?.grace_period }} Hari</th>
      </tr>
      <tr>
        <td>Denda</td>
        <th scope>{{ disbursment?.fine_rate }}%</th>
      </tr>
      <tr>
        <td>No. Invoice</td>
        <th scope>{{ disbursment?.invoice.invoice_no }}</th>
      </tr>
      <tr>
        <td>Nilai Invoice</td>
        <th scope>{{ rupiahFormat(disbursment?.invoice.invoice_nominal) }}</th>
      </tr>
      <tr>
        <td>Tanggal Jatuh Tempo Invoice</td>
        <th scope>
          {{ fullDateFormat(disbursment?.invoice?.invoice_due_date) }}
        </th>
      </tr>
      <tr>
        <td>Tanggal Pengajuan Pinjaman</td>
        <th scope>{{ fullDateFormat(disbursment?.created_at) }}</th>
      </tr>
      <tr>
        <td>Tenor</td>
        <th scope>{{ disbursment?.tenor }} Hari</th>
      </tr>
      <tr>
        <td>Jadwal Pencairan</td>
        <th scope>
          {{ fullDateFormat(disbursment?.disbursement_date_request) }}
        </th>
      </tr>
      <tr>
        <td>Persentase Pencairan</td>
        <th scope>{{ disbursment?.percentage_of_disbursed }}%</th>
      </tr>
      <tr>
        <td>Nilai Pinjaman</td>
        <th scope>{{ rupiahFormat(disbursment?.principal_value) }}</th>
      </tr>
      <tr>
        <td>Bunga</td>
        <th scope>{{ disbursment?.interest_rate }}%</th>
      </tr>
      <tr>
        <td>Nilai Bunga</td>
        <th scope>{{ rupiahFormat(disbursment?.interest_value) }}</th>
      </tr>
      <tr>
        <td>Admin Fee</td>
        <th scope>{{ rupiahFormat(disbursment?.admin_fee_value) }}</th>
      </tr>
      <tr>
        <td>Nilai yang Diterima</td>
        <th scope>{{ rupiahFormat(disbursment?.disbursement_value) }}</th>
      </tr>
      <tr>
        <td>Nilai yang Harus Dibayarkan</td>
        <th scope>{{ rupiahFormat(disbursment?.repayment_value) }}</th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: {
    disbursment: {
      type: Object,
    },
  },
  methods: {
    countDisbursment(disbursment, percentage) {
      return disbursment * (percentage / 100);
    },
  },
};
</script>

<style scoped>
th,
td {
  width: 50%;
}
</style>
